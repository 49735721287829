<template>
  <v-container>
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <v-row class="mb-3"> <h1>Workshop Drawings</h1></v-row>
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-row>
        <v-col cols="12" md="6">
    
        </v-col>
        <v-col cols="12" md="6" class="text-right">
          <!-- <v-btn elevation="2" class="mr-3"  :to="{ name: 'Page'+role+'EventCheckpointTemplate'}"> {{$t("action.view_templates")}} </v-btn> -->
          <v-btn
          text
          @click="$router.go(-1)"
        >
          {{$t("action.cancel")}}
        </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-tabs>
          <v-tab>{{$t("model.filterBy.all")}}</v-tab>
          <v-tab-item>
            <div>
              <GridDrawing
              ref="all_items"
                :callbackSelect="selectDrawing"
                :callbackError="showError"
                :parent="parent"
                :currentSelectedItems="selectedItems"
              ></GridDrawing>
            </div>
          </v-tab-item>
        </v-tabs>
      </v-row>
    </v-form>
  </v-container>
</template>



<script>
import GridDrawing from "@/components/Moderator/Drawing/GridDrawing";
export default {
  props: [
    "items",
    "self",
    "role",
    "model",
    "url",
    "action",
    "callbackSuccess",
    "parent"
  ],
  data: () => ({
    count: 0,
    selectedItems:[],
    selectedItemsMochiIds:[],
    removeCount: 0,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    form: {},
  }),
  components: {
    GridDrawing,
  },
  created() {
    //BOC:[api]
    this.api.url = this.url;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },

  methods: {
    showError(error) {
      this.api.isError = true;
      this.api.error = error;
    },

    selectDrawing(items) {
      this.length = 0;
      this.selectedItems = items
      this.selectedItemsMochiIds = this.$_.map(this.selectedItems,'mochiId')
      this.count = this.selectedItems.length * 5;
      setTimeout(() => {
        this.$refs["selected_items"].fetch();
      }, 100);
      //this.chapterKey =null
    },

    removeQuestion(data) {
      this.selectedItems = data
      this.selectedItemsMochiIds = this.$_.map(this.selectedItems,'mochiId')
      this.count = this.selectedItems.length * 5;
      this.removeCount = 1;
      setTimeout(() => {
        this.$refs["all_items"].update();
      }, 100);
    },

    submit() {
      this.form.mochiIds =  this.selectedItemsMochiIds
      this.api.params = this.$_.clone(this.form);
      this.$api.fetch(this.api);
    },
  },
  mounted(){
this.selectedItems = this.$_.cloneDeep(this.items)
this.selectedItemsMochiIds = this.$_.map(this.selectedItems,'mochiId')
      this.count = this.selectedItems.length * 5;
      setTimeout(() => {
        this.$refs["all_items"].update();
        this.$refs["selected_items"].fetch();
      }, 100);
  },
  beforeMount() {},
};
</script>

<style>
</style>