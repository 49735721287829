<template>
  <v-container>
    <div v-if="api.isLoading" class="text-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col v-for="item in data" :key="item.id" cols="12" sm="4">
        <v-card height="100%">
          <v-card-title>{{ item.mochiId }}</v-card-title>
          <div>
            <v-divider></v-divider>
            <div class="pa-1" style="min-height: 180px">
              <v-img
                contain
                width="100%"
                max-height="180"
                :src="item.imageUrl"
              ></v-img>
            </div>
            <v-divider></v-divider>
          </div>
          <v-spacer></v-spacer>
          <div class="d-flex justify-center align-center pa-1">
            <v-btn text @click="selectItem(item)" color="primary" :loading="selectedItem && selectedItem.id == item.id"
              >Send Reward</v-btn
            >
          </div>
        </v-card>
      </v-col>
    </v-row>
    <div class="d-flex align-center justify-center pa-3">
      <div  v-if="data.length== 0">No Data</div>
    </div>
    <template>
      <div class="text-center" v-if="!api.isLoading">
        <v-container>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  v-model="page"
                  class="my-4"
                  :length="totalPages"
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div> </template
  >
  </v-container>
</template>

<script>
export default {
  props: ["callbackError", "callbackSelect", "currentSelectedItems", "parent"],
  data: () => ({
    checkbox: true,
    selectedItems: [],
    selectedItemsMochiIds: [],
    selectedItem:null,
    dialogs: {
      view: false,
    },
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    rewardApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
     //BOC:[pagination]
     page: 1,
    totalPages: 5,
    limit: 10,
    isLoading: false,
    //EOC
  }),
  watch: {
    page: function () {
      this.fetch();
    },
  },
  components: {},
  created() {
    //BOC:[api]
    this.api.method = "get";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
      //BOC:[parent]
      this.callbackError(e);
      //EOC
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp.data;
        this.page = resp.page;
        this.totalPages = resp.totalPages;
    };
    //EOC

    //BOC:[api]
    this.rewardApi.url = `${this.$api.servers.game}/api/v1/${this.$_getLocale()}/moderator/gallery/${2023}/theme/${this.$route.params.id}/galleryAwardDrawing/send?page=${this.page}`;
    this.rewardApi.callbackReset = () => {
      this.rewardApi.isLoading = true;
      this.rewardApi.isError = false;
    };
    this.rewardApi.callbackError = (e) => {
      this.rewardApi.isLoading = false;
      this.rewardApi.isError = true;
      this.rewardApi.error = e;
      //BOC:[parent]
      this.callbackError(e);
      //EOC
    };
    this.rewardApi.callbackSuccess = () => {
      this.rewardApi.isLoading = false;
      this.selectedItem = null
      this.fetch();
    };
    //EOC
  },
  methods: {
    update() {
      this.selectedItems = this.$_.cloneDeep(this.currentSelectedItems);
      this.selectedItemsMochiIds = this.$_.map(this.selectedItems, "mochiId");
    },
    selectItem(item) {
      this.selectedItem = item
     this.rewardApi.params = {workshopDrawingId: item.id}
     this.$api.fetch(this.rewardApi);
    },
    fetch() {
      this.api.url = `${
        this.$api.servers.game
      }/api/v1/zh/moderator/gallery/${2023}/theme/${
        this.$route.params.id
      }/workshopDrawing/notRewarded`;
      this.$api.fetch(this.api);
    },

  },
  mounted() {
    this.selectedItems = this.$_.cloneDeep(this.currentSelectedItems);
    this.selectedItemsMochiIds = this.$_.map(this.selectedItems, "mochiId");
    this.fetch();
  },
};
</script>
<style>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
v-checkbox {
  align-items: center;
  justify-content: center;
}
.checkbox label {
  color: #1976d2 !important;
}
</style>

