<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumbV1 :items="breadcrumbs"></ABreadcrumbV1>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <PageManage
    v-if="!api.isLoading"
      :role="role"
      :model="model"
      :parent="parent"
      :url="`${$api.servers.game}/api/v1/${this.$_getLocale()}/moderator/gallery/${2023}/theme/${$route.params.id}/galleryAwardDrawing/add`"
      action="add"
      :callbackSuccess="callbackSuccess"
      :items="items"
    ></PageManage>
    <!-- EOC -->
  </v-container>
</template>

<script>
//BOC:[model]
import modelParent from "@/models/items/theme";
import model from "@/models/items/eventTopic";
//EOC
import PageManage from "@/components/Moderator/Drawing/PageManage";
import { mapState } from "vuex";
export default {
  components: {
    PageManage,
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  props: ["parent"],
  data: () => ({
    items: [],
    filter: [],
    length: 0,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[model]
    modelParent: modelParent,
    model: model,
    //EOC
    //BOC:[role]
    role: "Moderator",
    //EOC
  }),
  created() {
    //BOC:[api]
    this.api.method = "get";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
      //BOC:[parent]
      this.callbackError(e);
      //EOC
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.items =  this.$_.map(resp, item => this.$_.pick(item, ['mochiId', 'themeKey', 'imageUrl']));
    };
    //EOC
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text:this.$t("model.name.dashboard"),
      to: { name: "Page" + this.role + "Dashboard" },
      exact: true,
    });
    //
    this.breadcrumbs.push({
        text:this.$t("model.name." +this.modelParent.name.plural.toLowerCase()),
        to:this.$_getRouteBrowse(this.role,this.modelParent.key),
        exact:true,
      })
    //
    this.breadcrumbs.push({
        text:(this.grarent && this.parent.name) ? this.parent.name : `${this.$t("model.name." + this.modelParent.name.singular.toLowerCase())} ${this.$route.params.id}`,
        to:this.$_getRouteRead(this.role,this.modelParent.key,this.$route.params.id,this.parent),
        exact:true,
      })

      // let parentPath = this.$_.cloneDeep(this.breadcrumbs[this.breadcrumbs.length-1].to)
      //  parentPath.query = {tab:'SchoolYear'}

      // this.breadcrumbs.push({
      //   text:this.$t("model.name." + this.parent.name.plural.toLowerCase()),
      //   to:parentPath,
      //   exact:true,
      // })
   
    this.breadcrumbs.push({
      text: `Manage Drawings`,
      to: {
        name: "PageModeratorThemeWorkshopDrawingManage",
        params: { id: this.$route.params.id, parent: this.parent },
      },
      exact: true,
    });
    //EOC
  },
  mounted() {
    
  },
  methods: {
    fetch() {
      this.api.url = `${this.$api.servers.game}/api/v1/zh/moderator/gallery/${this.parent.year}/theme/${this.$route.params.id}/galleryAwardDrawing`
      this.$api.fetch(this.api);
    },
  
    callbackSuccess() {
        let redirect = {name:'PageModeratorThemeRead',params:{id:this.$route.params.id}}
        redirect.query = {tab:'GalleryAwardDrawing'}
        this.$router.push(redirect)
      },
  },


};
</script>